import StoryblokClient, { Richtext } from 'storyblok-js-client';
import { ref } from '@vue/composition-api';

export function useStoryblok() {
  const Storyblok = new StoryblokClient({ accessToken: process.env.NUXT_ENV_STORYBLOK });

  function renderRichText(text: Richtext) {
    return ref(Storyblok.richTextResolver.render(text));
  }

  return {
    renderRichText,
  };
}
