import { ref } from "@vue/composition-api";var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"review__content fc-col-span-5 md:fc-col-span-8"},[_c('div',{ref:"reviewContentEl",staticClass:"fc-text-black fc-text-small review__content-text",class:_vm.reviewContentClasses,domProps:{"innerHTML":_vm._s(_vm.modifyBody)}}),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"},{name:"track-click",rawName:"v-track-click",value:(("Review" + _vm.codeId + "_SeeMoreLess")),expression:"`Review${codeId}_SeeMoreLess`"}],staticClass:"review__button fc-margin-bottom-7 review__content-expand fc-margin-top-2",class:_vm.expandButtonClass,attrs:{"type":"button"},on:{"click":_vm.toggleExpanded}},[_c('p',{staticClass:"fc-text-blue fc-margin-y-2 fc-underline fc-text-small"},[_vm._v("\n      "+_vm._s(_vm.showText)+"\n    ")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.reviewImgs),expression:"reviewImgs"}],staticClass:"review__image-container fc-grid fc-col-span-1 md:fc-col-span-2 fc-margin-bottom-5"},_vm._l((_vm.reviewImgs),function(img){return _c('button',{directives:[{name:"track-click",rawName:"v-track-click",value:(("Review" + _vm.codeId + "_" + (img.filename))),expression:"`Review${codeId}_${img.filename}`"}],key:((img.id) + "_" + _vm.codeId),staticClass:"review__image-wrapper fc-col-span-1 md:fc-col-span-2",on:{"click":function($event){return _vm.openModal(img)}}},[_c('FCImage',{attrs:{"fc-provider":"storyblok","nuxt-image-props":{
          src: img.filename,
          alt: img.alt,
          sizes: 'xs:400px',
          width: '200',
          height: '200',
          loading: 'lazy',
        },"display-type":"cover"}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }