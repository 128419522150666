import { ref } from '@vue/composition-api';

export function useExpand() {
  const isExpanded = ref(false);

  function toggleExpanded() {
    isExpanded.value = !isExpanded.value;
  }

  return { isExpanded, toggleExpanded };
}
